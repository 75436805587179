import React from 'react'
import Layout from "../components/Layout"

export default function NotFound() {
    return (
        <Layout>
          <div>Hello world!</div>
        </Layout>
    )
}
